.input-number-controlled > .ant-input-number-handler-wrap {
  display: none;
}

.ant-input-number.input-number-controlled {
  width: 48px !important;
  margin: 0 10px !important;
}

.input-number-controlled .ant-input-number-input {
  text-align: center !important;
}
