body,
html,
#root,
#root > div {
    font-family: 'Manrope', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    overflow: auto;
    height: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.pac-container {
    transition: top 0.1s ease-in;
}
.pac-target-input {
    width: 100%;
    padding: 4px 11px;
}
